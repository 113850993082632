import makeRequest from "api/makeRequest";
import url from "api/urls";

export class ClientService {
    static async createClient( payload:any) {
        return await makeRequest(url.client.createClient, "post", payload)
    }

    static async getAllClient(examId:any) {
        return await makeRequest(url.client.getAllClient + "/" + examId, "GET")
    }

    static async updateClientPassword(clientId:any, payload:any) {
        return await makeRequest(url.client.updateClientPassword + "/" + clientId, "put", payload)
    }

    static async deleteClient(clientId:any) {
        return await makeRequest(url.client.deleteClient + "/" + clientId, "delete")
    }
}